<template>
    <div class="container-xxl flex-grow-1 container-p-y">
        <h4 class="fw-bold py-3 mb-4">
            <span class="text-muted fw-light">Ustawienia konta /</span> Powiadomienia
        </h4>

        <div class="row">
            <div class="col-md-12">
                <ul class="nav nav-pills flex-column flex-md-row mb-3">
                    <li class="nav-item"><router-link class="nav-link" to="/user/me"><i class="bx bx-user me-1"></i>
                            Konto</router-link></li>
                    <li class="nav-item"><router-link class="nav-link" to="/user/me/secuity"><i
                                class="bx bx-lock-alt me-1"></i> Bezpieczeństwo</router-link></li>
                    <li class="nav-item"><router-link class="nav-link active" to="/user/me/notifications"><i
                                class="bx bx-bell me-1"></i> Powiadomienia</router-link></li>
                </ul>
                <UserNotification :user_id="user_id" v-if="user_id"></UserNotification>
            </div>
        </div>

    </div>
</template>
<script>
import UserNotification from './components/UserNotification.vue';

export default {
    components: { UserNotification },
    data: function () {
        return {
        }
    },
    computed: {
        user_id: function () {
            return this.$store.state.user.id;
        }
    },
    created: function () {

    },
}
</script>